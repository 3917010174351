<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="1450px"
    persistent
    :retain-focus="false"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("FORMS.position") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.career"
                v-model="formModel.status"
                :items="statuses.career.career"
                :label="$t('FORMS.status')"
                item-text="value"
                item-value="key"
              >
                <template slot="selection" slot-scope="slotProps">
                  {{ $t("STATUSES." + slotProps.item.value) }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="navi-text">{{
                    $t("STATUSES." + slotProps.item.value)
                  }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                :rules="nameRules"
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].name"
                :key="selectedLocale.lang + '-name'"
                :label="$t('FORM_INPUT_NAMES.name')"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.name']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.name'] = ''
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type:'ckeditor',
                  label: $t('FORMS.intro'),
                  height:'550',
                  editorConfig: editorConfig                 
                }"
                customFieldName="intro"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .intro
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="mainImage"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .mainImage || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                    {
                      name: 'description',
                      label: 'FORM_INPUT_NAMES.description',
                      type: 'text',
                    },
                  ],
                  selectButtonText: $t(
                    'FORM_INPUT_NAMES.select_image'
                  ),
                  initialColumnClass:
                    'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <ckeditor
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].text"
                :key="selectedLocale.lang + '-text'"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.text']
                "
                @input="
                  messages['translations.' + selectedLocale.lang + '.text'] = ''
                "
                :config="editorConfig1"
              ></ckeditor>
              <small
                v-if="messages['translations.' + selectedLocale.lang + '.text']"
                style="color: red"
              >
                {{ messages["translations." + selectedLocale.lang + ".text"] }}
              </small>
            </v-col>
          </v-row>
          <v-row>
                <v-col cols="12">
                  <CustomFieldComponent
                    v-if="
                      formModel.translations[selectedLocale.lang] &&
                      formModel.translations[selectedLocale.lang].custom_fields
                    "
                    :config="{
                      type: 'text',
                      label: 'FORMS.seo_title',
                      charLimit: 60,
                    }"
                    customFieldName="seoTitle"
                    :value="
                      formModel.translations[selectedLocale.lang].custom_fields
                        .seoTitle
                    "
                    @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                  >
                  </CustomFieldComponent>
                </v-col>
                <v-col cols="12">
                  <CustomFieldComponent
                    v-if="
                      formModel.translations[selectedLocale.lang] &&
                      formModel.translations[selectedLocale.lang].custom_fields
                    "
                    :config="{
                      type: 'textarea',
                      label: 'FORMS.seo_description',
                      charLimit: 160,
                    }"
                    customFieldName="seoDescription"
                    :value="
                      formModel.translations[selectedLocale.lang].custom_fields
                        .seoDescription
                    "
                    @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                  >
                  </CustomFieldComponent>
                </v-col>
          </v-row>
          <v-row>
                <v-col cols="12" sm="8" md="8">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-2">
                      <CustomFieldComponent
                        v-if="
                          formModel.translations[selectedLocale.lang] &&
                          formModel.translations[selectedLocale.lang]
                            .custom_fields
                        "
                        :config="{
                          type: 'text',
                          label: 'FORM_INPUT_NAMES.og_title',
                        }"
                        customFieldName="og_title"
                        :value="
                          formModel.translations[selectedLocale.lang]
                            .custom_fields.og_title || ''
                        "
                        @handleChangeCustomFieldValue="
                          handleChangeCustomFieldValue
                        "
                      >
                      </CustomFieldComponent>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <CustomFieldComponent
                        v-if="formModel && formModel.custom_fields"
                        :config="{
                          type: 'multi_select',
                          multiple: false,
                          label: $t('FORM_INPUT_NAMES.og_type'),

                          items: og_types,
                        }"
                        customFieldName="og_type"
                        :value="formModel.custom_fields.og_type || 'website'"
                        @handleChangeCustomFieldValue="
                          handleChangeCustomFieldValue
                        "
                      >
                      </CustomFieldComponent>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <CustomFieldComponent
                        v-if="
                          formModel.translations[selectedLocale.lang] &&
                          formModel.translations[selectedLocale.lang]
                            .custom_fields
                        "
                        :config="{
                          type: 'textarea',
                          label: 'FORM_INPUT_NAMES.og_description',
                        }"
                        customFieldName="og_description"
                        :value="
                          formModel.translations[selectedLocale.lang]
                            .custom_fields.og_description || ''
                        "
                        @handleChangeCustomFieldValue="
                          handleChangeCustomFieldValue
                        "
                      >
                      </CustomFieldComponent>
                    </v-col>
                    <v-col cols="12">
                      <CustomFieldComponent
                        v-if="formModel.custom_fields"
                        :config="{
                          type: 'text',
                          label: 'FORMS.canonical',
                          charLimit: 60,
                        }"
                        customFieldName="canonical"
                        :value="formModel.custom_fields.canonical"
                        @handleChangeCustomFieldValue="
                          handleChangeCustomFieldValue
                        "
                      >
                      </CustomFieldComponent>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <CustomFieldComponent
                    v-if="
                      formModel.translations[selectedLocale.lang] &&
                      formModel.translations[selectedLocale.lang].custom_fields
                    "
                    customFieldName="og_image"
                    :value="
                      formModel.translations[selectedLocale.lang].custom_fields
                        .og_image || ''
                    "
                    :config="{
                      type: 'media_selector',
                      maxFiles: 1,
                      selectButtonText: 'FORM_INPUT_NAMES.og_image',
                      initialColumnClass:
                        'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
                    }"
                    @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                  >
                  </CustomFieldComponent>
                </v-col>
              </v-row>
        </v-form>
      </v-card-text>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions> -->
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn> -->
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
      <SnackBarInfoComponent :snackbarInfo="snackbarInfo">
      </SnackBarInfoComponent>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { ENDPOINT, PERMISSION_TO } from "./Careers";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import SnackBarInfoComponent from "@/view/components/SnackBarInfoComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";

const TRANSLATED_ATRIBUTES = [
  "name",
  "text",
  //"requirements",
  //"tasks",
  //"benefits",
];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [
  // "status",
  // "position",
  // "custom_fields",
  // "attachments",
  "intro",
  "mainImage",
  //"serviceImage",
  "seoTitle",
  "seoDescription",
  "og_title",
  "og_description",
  "og_url",
  "og_site_name",
  "og_image",
];

export const initialFormData = () => ({
  id: null,
  status: 1,
  position: 1,
  attachments: null,
  translations: {},
  // custom_fields: [],
});

export default {
  name: "CookieModalForm",
  props: ["modalData", "permissions", "statuses"],
  components: { CustomFieldComponent, SnackBarInfoComponent },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      endPoint: ENDPOINT,
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      editorConfig1: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },
      // editorConfig2: {
      //   htmlEncodeOutput: false,
      //   entities: false,
      //   extraPlugins: "justify,font,copyformatting",
      //   filebrowserBrowseUrl:
      //     window.location.origin +
      //     "/" +
      //     this.$router.resolve({
      //       name: "media_library",
      //       query: { component_in_window: 1 },
      //     }).href +
      //     "?type=Files",
      //   filebrowserImageBrowseUrl:
      //     window.location.origin +
      //     "/" +
      //     this.$router.resolve({
      //       name: "media_library",
      //       query: { component_in_window: 1 },
      //     }).href +
      //     "?type=Images",
      // },
      // editorConfig3: {
      //   htmlEncodeOutput: false,
      //   entities: false,
      //   extraPlugins: "justify,font,copyformatting",
      //   filebrowserBrowseUrl:
      //     window.location.origin +
      //     "/" +
      //     this.$router.resolve({
      //       name: "media_library",
      //       query: { component_in_window: 1 },
      //     }).href +
      //     "?type=Files",
      //   filebrowserImageBrowseUrl:
      //     window.location.origin +
      //     "/" +
      //     this.$router.resolve({
      //       name: "media_library",
      //       query: { component_in_window: 1 },
      //     }).href +
      //     "?type=Images",
      // },
      // editorConfig4: {
      //   htmlEncodeOutput: false,
      //   entities: false,
      //   extraPlugins: "justify,font,copyformatting",
      //   filebrowserBrowseUrl:
      //     window.location.origin +
      //     "/" +
      //     this.$router.resolve({
      //       name: "media_library",
      //       query: { component_in_window: 1 },
      //     }).href +
      //     "?type=Files",
      //   filebrowserImageBrowseUrl:
      //     window.location.origin +
      //     "/" +
      //     this.$router.resolve({
      //       name: "media_library",
      //       query: { component_in_window: 1 },
      //     }).href +
      //     "?type=Images",
      // },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig", "cookieCategoryCollection"]),

    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    computedEditorConfig() {
      return Object.assign({}, this.editorConfig);
    },
    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            " " +
            this.$helpers.getTranslated(this.formModel.translations).name
        : this.$t("MENU.NEW") + " " + this.$t("FORMS.position");
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.userLanguages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(ENDPOINT + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
              // this.formModel.custom_fields = this.initCustomFields();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();

          // this.formModel.custom_fields = this.initCustomFields();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    ...mapActions(["fetchCookieCategory"]),

    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      this.$refs.form.validate();

      if (this.formValid) {
        this.loader = true;
        this.resetErrorMessages();
        if (model.id) {
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.showSnackBarInfo();
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(ENDPOINT, model)
            .then(({ data }) => {
              this.showSnackBarInfo();
              setTimeout(() => {
                this.$emit("saveModalForm", data);
              }, 1000);
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },
  },

  mounted() {
    this.fetchCookieCategory();
    this.setTranslatedAttributes();
  },
};
</script>
